<template>
  <b-modal
    size="xl"
    id="facial-device-page-user-modal"
    title-class="h4 font-weight-bold"
    :title="device.name + ' Employee List'"
    footer-bg-variant="light"
  >
    <b-overlay :show="busy" spinner-variant="primary">
      <div class="d-flex mb-5 flex-wrap">
        <div class="symbol symbol-60 symbol-light mr-5">
          <span class="symbol-label svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg
              class="svg-icon-5x"
              src="/media/svg/icons/Devices/Tablet.svg"
            />
          </span>
        </div>
        <div class="d-flex flex-column p-2">
          <span class="font-weight-bolder">{{ device.code }}</span>
          <span>{{ device.model }}</span>
          <span class="text-muted"> Active </span>
        </div>

        <div class="ml-auto" v-if="currentTab === 0">
          <b-button
            class="font-weight-bolder btn-icon mr-2"
            variant="light-primary"
            v-on:click="removeFromDevice"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" /> </span
          ></b-button>

          <b-button
            variant="primary"
            class="font-weight-bolder"
            v-on:click="synchronize"
            ><span class="svg-icon menu-icon">
              <inline-svg src="/media/svg/icons/Devices/Generator.svg" />
            </span>
            {{ $t("DEVICEEMPLOYEE.SYNC") }}
          </b-button>
        </div>

        <div class="ml-auto" v-if="currentTab === 1">
          <b-button
            class="font-weight-bolder btn-hover-primary-dark"
            variant="primary"
            v-on:click="register"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/General/Save.svg" /> </span
            >{{ $t("DEVICEEMPLOYEE.SAVE") }}</b-button
          >
        </div>
      </div>

      <div class="d-flex my-5 justify-content-center">
        <b-alert
          fade
          :show="countdown"
          :variant="variant"
          @dismiss-count-down="countDownChanged"
          ref="facial-device-table-alert"
          dismissible
          >Success</b-alert
        >
      </div>

      <b-tabs pills vertical v-model="currentTab">
        <b-tab title="In" lazy>
          <FacialDeviceEmployeeTable
            :device-id="device.id"
          ></FacialDeviceEmployeeTable>
        </b-tab>
        <b-tab :title="$t('DEVICEEMPLOYEE.LIST')" lazy>
          <FacialDeviceEmployeeAvailableTable
            :device-id="device.id"
          ></FacialDeviceEmployeeAvailableTable>
        </b-tab>
      </b-tabs>
    </b-overlay>
    <template #modal-footer="{ ok, cancel }">
      <b-button variant="light" class="btn-hover-danger" @click="cancel">{{
        $t("BUTTON.CANCEL")
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import FacialDeviceEmployeeTable from "@/modules/device/components/facial/employee/FacialDeviceEmployeeTable";
import FacialDeviceEmployeeAvailableTable from "@/modules/device/components/facial/employee/FacialDeviceEmployeeAvailableTable";

export default {
  name: "FacialDeviceTableUserModal",
  components: { FacialDeviceEmployeeAvailableTable, FacialDeviceEmployeeTable },
  props: {
    deviceName: {
      type: String,
      default: "No Name",
    },
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentTab: 1,
      countdown: 0,
      busy: false,
      variant: "success",
    };
  },
  methods: {
    register() {
      console.log(this.device.id);
      this.busy = true;
      this.$store.dispatch("saveToDevice", this.device.id).finally(() => {
        this.busy = false;
      });
    },

    synchronize() {
      console.log(this.device.id);
      this.busy = true;
      this.$store
        .dispatch("synchronizeFacialDevice", this.device.id)
        .then(() => {
          this.$bvToast.toast("Successfully synchronized device.", {
            title: "Success",
            solid: true,
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Failed to synchronized device.", {
            title: "Failed",
            variant: "danger",
            headerClass: "h4",
            autoHideDelay: 5000,
          });
        })
        .finally(() => (this.busy = false));
    },

    removeFromDevice() {
      console.log(this.device.id);
      this.busy = true;
      this.$store
        .dispatch("removeFromDevice", this.device.id)
        .then(() => {
          this.$bvToast.toast("Successfully removed device.", {
            title: "Success",
            solid: true,
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Failed to remove device.", {
            title: "Failed",
            variant: "danger",
            headerClass: "h4",
            autoHideDelay: 5000,
          });
        })
        .finally(() => {
          this.busy = false;
        });
    },

    countDownChanged(dismissCountDown) {
      this.countdown = dismissCountDown;
    },
  },
};
</script>

<style scoped></style>
