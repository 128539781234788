<template>
  <div>
    <FacialDeviceTableControl class="mb-5" />

    <AppPageCard>
      <template #card-title>
        {{ $t("DEVICE.FACIAL.PAGE.HEADER") }}
      </template>

      <template #card-toolbar>
        <div
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </div>
        <b-button
          variant="primary"
          v-b-toggle.facial-device-list-sidebar
          v-on:click="openSideBar"
        >
          {{ $t("BUTTON.ADD") }}
        </b-button>
        <b-sidebar
          ref="facial-device-list-sidebar-ref"
          id="facial-device-list-sidebar"
          backdrop
          right
          lazy
          :title="$t('DEVICEFORM.HEADER')"
          sidebar-class="offcanvas p-7"
          no-header
          bg-variant="white"
        >
          <template #default="{ hide }">
            <div
              class="
                offcanvas-header
                d-flex
                align-items-center
                justify-content-between
                pb-5
              "
            >
              <h3 class="font-weight-bolder m-0">
                {{ $t("DEVICE.FACIAL.PAGE.HEADERFORM") }}
              </h3>
              <button
                class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
                v-on:click="hide"
              >
                <i class="ki ki-close icon-lg"></i>
              </button>
            </div>
            <div class="align-items-center">
              <FacialDeviceForm></FacialDeviceForm>
            </div>
          </template>
        </b-sidebar>
      </template>

      <div>
        <b-alert
          variant="info"
          :show="dismissCountDown"
          fade
          dismissible
          @dismissed="dismissCountDown = 0"
        >
          {{ successMessage }}
        </b-alert>
      </div>

      <FacialDeviceTable :reload="reload"></FacialDeviceTable>
    </AppPageCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import FacialDeviceTable from "@/modules/device/components/facial/fd/FacialDeviceTable";
import FacialDeviceForm from "@/modules/device/components/facial/fd/FacialDeviceForm";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import FacialDeviceTableControl from "@/modules/device/components/facial/control/FacialDeviceTableControl";

export default {
  name: "FacialDevicePage",
  components: {
    FacialDeviceTableControl,
    AppPageCard,
    FacialDeviceForm,
    FacialDeviceTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.DEVICE") },
      { title: this.$t("SIDEBAR.FACIAL"), route: "/device/facial" },
    ]);
  },

  data() {
    return {
      reload: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      formData: "",
      successMessage: "Successfully added Facial Device.",
    };
  },

  methods: {
    refresh() {
      this.reload = !this.reload;
    },

    closeSideBar(data) {
      this.formData = data;
      const sideBar = this.$refs["facial-device-list-sidebar-ref"];
      sideBar.hide();
      this.showAlert();
      this.reload = !this.reload;
    },

    openSideBar() {
      this.$store.commit("setRegisterDeviceFormSuccess", false);
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },

  computed: {
    ...mapGetters({ isSuccess: "getRegisterDeviceFormSuccess" }),
  },

  watch: {
    isSuccess(newSuccess) {
      console.log("Check is success", newSuccess);
      if (newSuccess) {
        this.refresh();
        this.$refs["facial-device-list-sidebar-ref"].hide();
        this.$bvModal.msgBoxOk(this.$t("ALERT.SUCCESS"), {
          centered: true,
        });
      }
    },
  },
};
</script>

<style scoped></style>
